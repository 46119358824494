<template>
<div>
  <div v-if="renderWith === 'Draftboard'">
  <b-container class="bv-example-row">
    <b-row class="text-center">
      <b-col></b-col>
      <b-col cols="10">

          <div v-if="medium==='text'">
            <b-card class="m-3">
              <b-card-text style="color: black;" v-html="content">
              </b-card-text>
            </b-card>
          </div>

          <div v-if="medium==='image'">
            <b-card class="m-3">
              <b-spinner v-if="loaded === false" type="grow" label="Loading..."></b-spinner>
              <b-overlay :show="!loaded" rounded="sm">
                <b-card-img :src="content" alt="Image"  @load="imgLoaded"></b-card-img>
              </b-overlay>
            </b-card>
          </div>

          <div v-if="medium==='sound'">
            <b-card class="m-3">
              <div>
                <div v-if="medium==='sound' && content.startsWith('https://')">
                  <audio controls>
                    <source :src="content" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div v-else v-html="content"></div>
              </div>
            </b-card>
          </div>

          <div v-if="medium==='video'">
            <b-card class="m-3">
              <vue-media-embed :source="'youtube://'+content" :auto-play="0" :allow-fullscreen="1" />
            </b-card>
          </div>

          <div v-if="medium==='link'">
            <b-card class="m-3">
              <b-button variant="outline-dark" :href="content" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                </svg>
              </b-button>
            </b-card>
          </div>
    </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
  </div>

  <div v-if="renderWith === 'Production'">
    <div v-if="medium==='text'">
      <b-card class="m-3">
        <b-card-text style="color: black;" v-html="content">
        </b-card-text>
      </b-card>
    </div>
    <div v-if="medium === 'image' && verticalScreen">
      <b-spinner v-if="loaded === false" type="grow" label="Loading..."></b-spinner>
      <b-overlay :show="!loaded" rounded="sm">
        <img :src="content" class="w-100" @load="imgLoaded">
      </b-overlay>
    </div>
    <div v-if="medium ==='image' && !verticalScreen" class="w-75">
      <b-spinner v-if="loaded === false" type="grow" label="Loading..."></b-spinner>
      <b-overlay :show="!loaded" rounded="sm">
        <img :src="content" alt="Image" @load="imgLoaded">
      </b-overlay>
    </div>
    <div>
      <div v-if="medium==='sound' && content.startsWith('https://')">
        <audio controls>
          <source :src="content" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
      <div v-else-if="medium==='sound'" v-html="content"></div>
    </div>
    <div v-if="medium==='video'" class="h-75 w-100">
      <vue-media-embed :source="'youtube://'+content" :auto-play="0" :allow-fullscreen="1" class="h-75"/>
    </div>

    <div v-if="medium==='link'">
      <b-container class="bv-example-row">
      <b-row class="text-center">
      <b-col></b-col>
      <b-col cols="10">
      <b-card class="m-3">
          <b-button variant="outline-dark" :href="content" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
              <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
              <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
            </svg>
          </b-button>
      </b-card>
      </b-col>
      <b-col></b-col>
      </b-row>
      </b-container>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Reader',
  props: {
    id: {
      type: undefined,
      default: undefined
    },
    medium: {
      type: undefined,
      default: ''
    },
    content: {
      type: undefined,
      default: ''
    }
  },
  // components: {},
  data: () => ({
    customToolbar: [],
    verticalScreen: true
  }),
  computed: {
    title: {
      get () {
        return this.$store.state.currentWriting.currentFunction.title
      },
      set (title) {
        this.$store.commit('currentWriting/testTitle', title)
        this.$store.commit('currentWriting/updateTitle', title)
      }
    },
    type: {
      get () {
        return this.$store.state.currentWriting.currentFunction.type
      },
      set (type) {
        this.$store.commit('currentWriting/changeType', type)
      }
    },
    renderWith () {
      return this.$store.state.currentWriting.currentFunction.renderWith
    },
    loaded: {
      get () {
        return this.$store.state.currentWriting.utilities.loaded
      },
      set () {
        this.$store.commit('currentWriting/imgLoaded')
      }
    }
  },
  created () {
    window.addEventListener('resize', this.myEventHandler)
  },
  mounted () {
    this.verticalScreen = false
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    imgLoaded () {
      this.$store.commit('currentWriting/imgLoaded')
    },
    myEventHandler (e) {
      // your code for handling resize...
      if (window.innerWidth < window.innerHeight) {
        this.verticalScreen = true
      } else {
        this.verticalScreen = false
      }
    }
  }
}
</script>

<style scoped>
div {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
</style>
