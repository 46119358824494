<template>
<div>
  <b-img class="mb-4" v-if="headerDisplay == 1" width="100" :src="primaryHeader" :href="authorLink"></b-img>
  <b-img class="mb-4" v-if="headerDisplay == 2" width="100" :src="secondaryHeader" :href="authorLink"></b-img>
  <h1 class="mb-4"> {{ title }} </h1>
  <Reader
    v-for="(aspect, index) in aspects"
    :key="index"
    name="aspect"
    v-bind:id="index"
    v-bind:medium="aspectMedia[index]"
    v-bind:content="aspect"></Reader>

  <div>
    <b-container class="bv-example-row">
    <b-row class="text-center">
      <b-col></b-col>
      <b-col cols="10">
      <b-navbar toggleable="lg" type="dark" variant="dark" fixed class="align-content-center">
      <b-button-group>
            <b-button :disabled="!predecessor" @click="goToPredecessor">
              <svg v-if="!predecessorButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left" viewBox="0 0 16 16">
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
              </svg>
              <b-img v-if="predecessorButton" :src="predecessorButton" width="30" height="30" rounded></b-img>
            </b-button>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button :disabled="parent === 'root'" @click="goToParent">
              <svg v-if="!parentButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"/>
                <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg>
              <b-img v-if="parentButton" :src="parentButton" width="30" height="30" rounded></b-img>
            </b-button>

            <b-button v-b-modal.modal-2
            :disabled="childList.length === 0">
              <svg v-if="!childButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-3" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
              </svg>
              <b-img v-if="childButton" :src="childButton" width="30" height="30" rounded></b-img>
            </b-button>
          </b-button-group>
          <b-button-group>
          <b-button @click="goToRoot" :disabled="!rootFunction">
          <svg v-if="!rootButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
          </svg>
          <b-img v-if="rootButton" :src="rootButton" width="30" height="30" rounded></b-img>
          </b-button>
          <b-button v-b-modal.modal-4
          :disabled="bookmarkList.length === 0">
            <svg v-if="!bookmarkButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks" viewBox="0 0 16 16">
              <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
              <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
            </svg>
            <b-img v-if="bookmarkButton" :src="bookmarkButton" width="30" height="30" rounded></b-img>
          </b-button>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button :disabled="!successor" @click="goToSuccessor">
              <svg v-if="!successorButton" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
              </svg>
              <b-img v-if="successorButton" :src="successorButton" width="30" height="30" rounded></b-img>
            </b-button>
          </b-button-group>
        </b-navbar>

        <b-modal
          id="modal-2"
          title="Go to Child"
          ok-title="Go"
          hide-footer>
          <b-list-group>
            <b-list-group-item
            v-for="(child, index) in childList"
            :key="index"
            v-bind:id="index"
            name="b-list-group-item"><b-button variant="link" @click="goToChild(child)">{{ child }}</b-button></b-list-group-item>
          </b-list-group>
        </b-modal>
        <b-modal
          id="modal-4"
          title="Go to Bookmark"
          ok-title="Go"
          hide-footer>
          <b-list-group>
            <b-list-group-item
            v-for="(bookmark, index) in bookmarkList"
            :key="index"
            v-bind:id="index"
            name="b-list-group-item"><b-button variant="link" @click="goToBookmark(bookmark)">{{ bookmark }}</b-button></b-list-group-item>
          </b-list-group>
        </b-modal>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
  <div>
    <br>
    <a :href="supportLink" target="_blank"><img :src="supportImage" width="500" class="mw-100" ></a><br>
    {{ projectTitle }} is powered by the <a href="https://artists.tools">Hypothetical</a> CMS, which is developed with ❤ by <a href="https://eyre.xyz">Eyre</a>
  </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import Reader from '@/components/Reader.vue'
// import WriteNav from '@/components/WriteNav.vue'

export default {
  name: 'About',
  components: {
    Reader
    // WriteNav
  },
  computed: {
    projectTitle () {
      return this.$store.state.currentWriting.currentProject.title
    },
    aspects () {
      return this.$store.state.currentWriting.currentFunction.aspects
    },
    aspectMedia () {
      return this.$store.state.currentWriting.currentFunction.aspectMedia
    },
    title () {
      return this.$store.state.currentWriting.currentFunction.title
    },
    type () {
      return this.$store.state.currentWriting.currentFunction.type
    },
    successor () {
      return this.$store.state.currentWriting.currentFunction.successor
    },
    predecessor () {
      return this.$store.state.currentWriting.currentFunction.predecessor
    },
    parent () {
      return this.$store.state.currentWriting.currentFunction.parent
    },
    childList () {
      return this.$store.state.currentWriting.currentFunction.childList
    },
    rootFunction () {
      return this.$store.state.currentWriting.currentProject.rootFunction
    },
    bookmarkList () {
      return this.$store.state.currentWriting.currentProject.bookmarks
    },
    predecessorButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.predecessorButton
    },
    successorButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.successorButton
    },
    childButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.childButton
    },
    parentButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.parentButton
    },
    rootButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.rootButton
    },
    bookmarkButton () {
      return this.$store.state.currentWriting.currentProject.lookSettings.bookmarkButton
    },
    primaryHeader () {
      return this.$store.state.currentWriting.currentProject.lookSettings.primaryHeader
    },
    secondaryHeader () {
      return this.$store.state.currentWriting.currentProject.lookSettings.secondaryHeader
    },
    headerDisplay () {
      return this.$store.state.currentWriting.currentFunction.headerDisplay
    },
    textColour () {
      return this.$store.state.currentWriting.currentFunction.textColour
    },
    authorLink () {
      return this.$store.state.currentWriting.currentProject.lookSettings.authorLink
    },
    supportLink () {
      return this.$store.state.currentWriting.currentProject.lookSettings.supportLink
    },
    supportImage () {
      return this.$store.state.currentWriting.currentProject.lookSettings.supportImage
    }
  },
  methods: {
    goToSuccessor () {
      this.$store.dispatch('currentWriting/goToSuccessor')
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    goToPredecessor () {
      this.$store.dispatch('currentWriting/goToPredecessor')
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    goToParent () {
      this.$store.dispatch('currentWriting/goToParent')
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    goToChild (child) {
      this.$bvModal.hide('modal-2')
      this.$store.dispatch('currentWriting/goToChild', child)
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    goToRoot () {
      this.$store.dispatch('currentWriting/goToRoot')
    },
    goToBookmark (bookmark) {
      this.$store.dispatch('currentWriting/goToBookmark', bookmark)
    }
  }
}
</script>

<style scoped>
h1 {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
</style>
